<template>
	<div class="content" v-if="!readonly">
		<el-upload
            class="upload-demo"
            :action="url"
            :headers="headers" 
            :data="data"
            :on-success="handleSuccess"
            :on-error="handleError"
            :before-remove="handleBeforeRemove"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :before-upload="handleBeforeUpload"
            :show-file-list="false"
            :limit="max"
            :accept="accept"
            :file-list="fileList">
            <el-button size="small" type="primary" :loading="loading">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" v-if="placeholder">{{ placeholder }}</div>
        </el-upload>
		<div class="a-flex-rsbc list-item" v-for="(item,index) in fileList" :key="index">
			<span class="a-flex-1 a-c-333 textOver1">{{ item.name || item.url }}</span>
			<span class="item-btn-red" @click="removeFile(index)">删除</span>
            <span class="item-btn-blue" @click="downLoadFile(item)">下载</span>
		</div>
	</div>
	<div class="content" v-else>
		<div class="a-flex-rsbc list-item" v-for="(item,index) in fileList" :key="index">
			<span class="a-flex-1 a-c-333 textOver1">{{ item.name || item.url }}</span>
			<span class="item-btn-blue" @click="downLoadFile(item)">下载</span>
		</div>
	</div>
</template>

<script>
    import Utils from '@/utils';
	export default {
		data () {
			return {
				fileList: [],
				url: this.$Config.axiosBaseUrl + this.$Config.apiUrl.uploadFile,
                headers: {
                    token: Utils.getToken(),
                    appId: this.$Config.appId,
                },
                data: {
                    key: "company/upload",
                },
                loading: false
			}
		},
		props:{
			taxFiles: {
				default: []
			},
			max:{
				default: 10
			},
            accept:{
                default: '.pdf'
            },
			placeholder:{
				default: '只能选择.pdf文件'
			},
			readonly:{
				default: false
			},
            readonlyName:{
                default: '提现发票_'
            }
		},
		watch:{
			fileList:{
				handler(val) {
					this.$emit('update:taxFiles', val)
				},
				deep: true
			},
		},
		mounted() {
			if(this.readonly){
				if(this.taxFiles && this.taxFiles.length){
					this.fileList =this.taxFiles.map(item=>{
						return {
							name: item.name,
                            url: item.url
						}
					})
				}
			}
		},
		methods:{
			handleSuccess (response, file, fileList) {
                let that = this
                if (response.code == 0) {
                    this.fileList.push({
                        name: response.data.split('/')[response.data.split('/').length-1],
                        url: response.data
                    })
                } else {
                    this.fileList = []
                    this.$message.error(response.msg)
                }
                this.loading = false
            },
            handleError () {
                this.$message.error(`文件上传失败`)
                this.loading = false
            },
            handleBeforeRemove (file, index) {
                let that = this;
                return this.$confirm(`确定移除 ${file.name}？`, {
                    callback: (action) => {
                        if (action == "confirm") {
                            that.fileList.splice(index, 1);
                        }
                    },
                });
            },
            handleBeforeUpload () {
                this.loading = true
            },
            handleRemove () {

            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前最多只允许上传${this.max}个文件`)
            },
			removeFile (index) {
                this.$confirm('是否确认删除文件？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.fileList.splice(index, 1);
                })
			},
            downLoadFile (datas) {
                let timeStamp = new Date().getTime()
                if(datas.url){
                    this.$Utils.download(datas.url,this.readonlyName+this.$getDay.getTimeStampToStr(timeStamp,'YYYY-MM-DD HH:mm:ss'))
                }else{
                    this.$message.warning('暂无可下载文件')
                }
            }
		}
	}
</script>

<style lang="scss" scoped>
	.list-item{
		background: #F5F7FA;
        width: 500px;
		padding: 6px 12px;
		margin-bottom: 12px;
        font-size: 14px;
        border-radius: 4px;
	}
	.item-btn-blue{
		font-size: 14px;
		color: #007af0;
        cursor: pointer;
	}
	.item-btn-red{
		font-size: 14px;
		color: #F56C6C;
		margin: 0 8px;
        cursor: pointer;
	}
</style>