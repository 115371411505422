<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700 a-ptb-6">买桩记录</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editPurchase()">添加采购</el-button>
            </div>
        </div>
        <el-card class="el-main goodsOrder">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input label="采购ID" v-model="pageParam.params.purchaseId" />
                <le-input label="物流编号" v-model="pageParam.params.trackingNo" />
                <le-input label="设备编号" v-model="pageParam.params.deviceCode" />
                <le-select-local-search label="执行状态" v-model="pageParam.params.purchaseState" :options="purchaseStateDic" />
                <le-company-solo-select label="商户" placeholder="请选择（可输入搜索）" v-model="pageParam.params.companyId"></le-company-solo-select>
            </le-search-form>
            <le-pagview ref="goodsOrderPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.goodsOrderList">
                <el-table 
                    ref="goodsOrderList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    :border="true"
                    :span-method='mergeCols'
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column label="采购ID" min-width="120" fixed="left">
                        <template slot-scope="{ row }">
                            <span>{{ row.id || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买数量" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.number || '0' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="设备型号" min-width="120">
                        <template slot-scope="{row}">
                            <span>{{ row.deviceType | initDic(devTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买单价" min-width="120">
                        <template slot-scope="{ row }">
                            <span>&yen;{{ utils.numFormat(row.amount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="服务费" min-width="130">
                        <template slot-scope="{ row }">
                            <span>&yen;{{ utils.numFormat(row.serviceAmount) }}/台/{{ row.periods }}年</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总金额(元)" min-width="120">
                        <template slot-scope="{ row }">
                            <span>&yen;{{ utils.numFormat(row.totalAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="付款方式" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payType | initDic(payTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="物流编号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.trackingNo || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="执行情况" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.purchaseState | initDic(purchaseStateDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="执行时间" min-width="160">
                        <template slot-scope="{ row }">
                            <span class="a-c-error">{{ row.executeTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买时间" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="使用情况" min-width="120">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>使用情况</span>
                                <el-tooltip class="item" effect="dark" content="" placement="top">
                                    <div slot="content">
                                        过去7天有在线设备数/总设备数
                                    </div>
                                    <span class="el-icon-question"></span>
                                </el-tooltip>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.lastSevenOnline || '0' }}/{{ row.totalDeviceNumber || '0' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" fixed="right" label="操作" class="a-flex-rcc">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="editPurchase(row,'info')" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="还款内容" placement="top">
                                <img src="../../assets/icon/option-refund.png" class="a-wh-16" @click="toRepayment(row)" v-if="row.purchaseState == 3 && $_has(110)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="editPurchase(row,'edit')" v-if="row.purchaseState == 1" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="立即执行" placement="top">
                                <img src="../../assets/icon/option-play.png" class="a-wh-16" @click="startPay(row)" v-if="row.purchaseState == 2" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="发货" placement="top">
                                <img src="../../assets/icon/option-send.png" class="a-wh-18" @click="sendGoods(row)" v-if="row.purchaseState == 1" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <delivery ref="delivery" @success="deliverySuccess"></delivery>
    </div>
</template>
<script>
import utils from '../../utils/util'
import delivery from './child/delivery.vue';
export default {
  components: { delivery },
    data() {
        return {
            utils: utils,
            tableData: [],
            cosTableData: [],
            pageParam: null,
            purchaseStateDic: [],
            devCategroyDic: [],
            devTypeDic: [],
            payTypeDic: []
        }
    },
    created() {
        this.pageParam = {
            url: this.$Config.apiUrl.purchaseChargerPage,
            method: "post",
            params: {
                companyId: "",
                startTime: "",
                endTime: "",
                payType: '', //支付方式:1分期,2直采
                purchaseId: '',
                purchaseState: "",//采购状态:1待发货,2待执行,3已执行
                trackingNo: "",
                deviceCode: ''
            },
            freshCtrl: 1,
        };
        this.getDevCategroy()
        this.getDevType()
        this.$getDic('purchaseState','select').then(res=>{ this.purchaseStateDic = res; })
        this.$getDic('purchasePayType').then(res=>{ this.payTypeDic = res; })
    },
    activated () {
        this.pageParam.freshCtrl++;
    },
    methods: {
        setTableData(data) {
            let arr = []
            // 遍历处理后台返回数据
            data.map((item,index)=>{
                item.purchaseDeviceList.map((ite,idx)=>{
                    let obj = {
                        ...ite,
                        companyId: item.companyId,
                        companyName: item.companyName,
                        createTime: item.createTime,
                        executeTime: item.executeTime,
                        id: item.id,
                        lastSevenOnline: item.lastSevenOnline,
                        payType: item.payType,
                        purchaseState: item.purchaseState,
                        totalAmount: item.totalAmount,
                        totalDeviceNumber: item.totalDeviceNumber,
                        trackingNo: item.trackingNo,
                    }
                    arr.push(obj)
                })
            })
            this.tableData = arr
            this.getCosTableData()
        },
        handlerRest() {
            this.pageParam.params = {
                companyId: "",
                startTime: "",
                endTime: "",
                payType: '', //支付方式:1分期,2直采
                purchaseId: '',
                purchaseState: "",//采购状态:1待发货,2待执行,3已执行
                trackingNo: "",
                deviceCode: ''
            };
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['goodsOrderPage'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        editPurchase (datas,type) {
            this.$router.push({
                path:'/goodsOrder/goodsOrder-edit',
                query: {
                    id: datas?datas.id:'',
                    editType: type?type:'add'
                }
            })
        },
        toRepayment (datas) {
            this.$router.push({
                path:'/finance/finance-repayment-dev-details',
                query: {
                    companyId: datas?datas.companyId:'',
                    purchaseId: datas?datas.id:'',
                }
            })
        },
        // 发货
        sendGoods (datas) {
            this.$refs['delivery'].id = datas.id
            this.$refs['delivery'].dialogVisible = true
        },
        deliverySuccess () {
            this.pageParam.freshCtrl++;
        },
        // 开始执行
        startPay (datas) {
            this.$confirm('是否确认开始执行扣款计划？', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.purchaseChargerExecute,
                    method: "post",
                    params: {
                        purchaseId: datas.id
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            }).catch(_=>{ })
        },
        // 合并行
        mergeCols ({ row, column, rowIndex, columnIndex }) {
            if(columnIndex !== 2 && columnIndex !== 3 && columnIndex !== 4 && columnIndex !== 5){
                for (let index = 0; index < this.cosTableData.length; index++) {
                    const element = this.cosTableData[index];
                    for (let c_index = 0; c_index < element.length; c_index++) {
                        if(rowIndex == element[c_index]){
                            if(c_index == 0){
                                return {
                                    rowspan: element.length,
                                    colspan: 1
                                }
                            }else{
                                return {
                                    rowspan: 0,
                                    colspan: 0
                                }
                            }   
                        }
                    }
                }
            }
        },
        // 获取需要合并单元格的数据
        getCosTableData () {
            this.cosTableData = []
            let obj = {}
            // 将相同编号的数据储存到同一个数组中，并以该编号为key值添加到obj对象中 以便之后取用
            this.tableData.map((item,index)=>{
                if(obj[item.id]){
                    obj[item.id].push(index)
                }else{
                    obj[item.id] = []
                    obj[item.id].push(index)
                }
            })
            // 将obj对象中数组长度大于1（即存在多个相同编号）的数据push到this.cosTableData中
            for (const key in obj) {
                if(obj[key].length > 1)
                this.cosTableData.push(obj[key])
            }
        },
        // 获取设备种类
        getDevCategroy () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getDeviceCategroyList,
                method: "post",
                params: {}
            }).then(res => {
                this.devCategroyDic = res.result.data.map(item=>{
                    return {
                        ...item,
                        label: item.deviceCategroy,
                        value: item.id
                    }
                })
            })
        },
        // 获取设备型号
        getDevType () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getDeviceType,
                method: "post",
                params: {
                    deviceCategroy: ''
                }
            }).then(res => {
                this.devTypeDic = res.result.data.map(item=>{
                    return {
                        ...item,
                        label: item.name,
                        value: item.id
                    }
                })
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.goodsOrder {
    padding: 8px 4px;
}
.s-btn-add{
    width: 105px;
}
.gocompany {
    color: #007AFF;
    cursor: pointer;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>